@mixin section-cover {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: rem-calc(8);
    bottom: 0;
    left: rem-calc(8);
    content: '';
    background-color: var(--section-cover-color, #{$c-red});
    border-radius: 12px;
    z-index: -1;
  }
}

@mixin dropdown-opened {
  &:global(.dropdownOpened) {
    @content;
  }
}

;@import "sass-embedded-legacy-load-done:88105";